/* eslint-disable no-param-reassign */

import { AxiosError, AxiosResponse } from "axios";
import { UseMutationResult, useQueryClient } from "react-query";

import {
  STRATEGY_CREATE_DSP,
  STRATEGY_CREATE_SP,
} from "App/components/AppRoutes/routes";
import { CardData } from "common/components/cardGroups/types";
import { TDropdownOption } from "common/components/form/Dropdown/types";
import { Icon } from "common/components/Icon";
import { Column } from "common/components/tables/types";
import { MessageKey, formatMessage } from "common/internationalization";
import { notifyOnClientErrorModal } from "common/utils/errors";
import { eventBus } from "common/utils/eventBus";
import { queriesNames } from "modules/api/consts";
import { useDuplicateStrategyMutation } from "modules/api/mutations/strategies";
import {
  AudienceSet,
  AudienceSetGoal,
  MarketAudienceSelection,
} from "modules/Audiences/types";
import { Channel, Format } from "modules/Reporting/types";

import {
  awarenessGoalKPIs,
  StrategyGoal,
  conversionGoalKPIs,
  KPIMetric,
  ProductLocation,
  mapGoalFormat,
  considerationGoalKPIs,
  CampaignFormat,
  StrategyEligibleChannel,
  strategyEligibleChannelsList,
} from "./types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onError = (error: AxiosError<any>) => notifyOnClientErrorModal(error);

export const MAX_MONEY_VALUE = 999_999_999.99;
export const EMPTY_CELL_VALUE = "—";

export const baseStrategySummaryColumns: { name: MessageKey; width: number }[] =
  [
    { name: "strategy.label.flightDate", width: 1 },
    { name: "words.markets", width: 1 },
    { name: "words.goal", width: 1 },
  ];

export const dspStrategySummaryColumns: { name: MessageKey; width: number }[] =
  [
    ...baseStrategySummaryColumns,
    { name: "strategy.label.strategyType", width: 1 },
    { name: "strategy.label.channelType", width: 1 },
  ];

export const spStrategySummaryColumns: { name: MessageKey; width: number }[] = [
  ...baseStrategySummaryColumns,
  { name: "strategy.label.strategyType", width: 1 },
];

export const campaignSummaryColumns: { name: MessageKey; width: number }[] = [
  { name: "strategy.label.flightDate", width: 1 },
  { name: "words.name", width: 1 },
  { name: "words.market", width: 1 },
  { name: "words.format", width: 1 },
];

export const metricsMap: Record<StrategyGoal, KPIMetric[]> = {
  AWARENESS: [...awarenessGoalKPIs],
  CONSIDERATION: [...considerationGoalKPIs],
  CONVERSION: [...conversionGoalKPIs],
};

export const mapSetsToFormData = (sets?: AudienceSet[]) => {
  if (!sets) return {};
  return sets.reduce((prev: MarketAudienceSelection, set: AudienceSet) => {
    if (prev[set.market]) prev[set.market]![set.id] = set;
    else prev[set.market] = { [set.id]: set };
    return prev;
  }, {});
};

export const ifVideoNoMultipleCategories = (
  selected: string[],
  categories: TDropdownOption[],
  formats?: Format[],
) => {
  const headers = [
    ...new Set(
      selected.flatMap((element) => {
        if (
          categories.some((parentCategory) => parentCategory.value === element)
        )
          return [element];
        return [];
      }),
    ),
  ];
  if (
    formats &&
    (["online_video", "streaming_tv"] as Format[]).some((f) =>
      formats.includes(f),
    ) &&
    headers.length > 1
  )
    return formatMessage("common.error.videoFormatExactOneProductCategory");
  return true;
};

export const mapKpi = (name: KPIMetric) => ({
  value: name,
  label: formatMessage(`strategy.metric.${name}`),
  additionalInfo: formatMessage(`strategy.metric.${name}.info`),
});

export const goalsMapped: Record<string, CardData<AudienceSetGoal>> = {
  // it can work like mapKpi when all goals are added
  awareness: {
    value: "AWARENESS",
    label: formatMessage("strategy.goals.AWARENESS"),
    additionalInfo: formatMessage("strategy.goals.dsp.awareness.info"),
    icon: <Icon name="AWARENESS" />,
  },
  conversion: {
    value: "CONVERSION",
    label: formatMessage("strategy.goals.CONVERSION"),
    additionalInfo: formatMessage("strategy.goals.dsp.conversion.info"),
    icon: <Icon name="CONVERSION" />,
  },
  consideration: {
    value: "CONSIDERATION",
    label: formatMessage("strategy.goals.CONSIDERATION"),
    additionalInfo: formatMessage("strategy.goals.dsp.consideration.info"),
    icon: <Icon name="CONSIDERATION" />,
  },
};

export const mapLocationsGoals: Record<
  ProductLocation,
  Partial<
    Record<
      StrategyGoal,
      {
        data: CardData<AudienceSetGoal>;
        kpis: Record<Format, CardData<KPIMetric>[]>;
      }
    >
  >
> = {
  NOT_SOLD_ON_AMAZON: {
    AWARENESS: {
      data: goalsMapped.awareness,
      kpis: {
        standard_display: mapGoalFormat.AWARENESS.standard_display.map((el) =>
          mapKpi(el),
        ),
        display: mapGoalFormat.AWARENESS.display.map((el) => mapKpi(el)),
        video: mapGoalFormat.AWARENESS.video.map((el) => mapKpi(el)),
        online_video: mapGoalFormat.AWARENESS.online_video.map((el) =>
          mapKpi(el),
        ),
        streaming_tv: mapGoalFormat.AWARENESS.streaming_tv.map((el) =>
          mapKpi(el),
        ),
        prime_deals: mapGoalFormat.AWARENESS.streaming_tv.map((el) =>
          mapKpi(el),
        ),
        mobile: mapGoalFormat.AWARENESS.mobile.map((el) => mapKpi(el)),
      },
    },
    CONSIDERATION: {
      data: goalsMapped.consideration,
      kpis: {
        standard_display:
          mapGoalFormat.CONSIDERATION_OFF_AMAZON.standard_display.map((el) =>
            mapKpi(el),
          ),
        display: mapGoalFormat.CONSIDERATION_OFF_AMAZON.display.map((el) =>
          mapKpi(el),
        ),
        video: mapGoalFormat.CONSIDERATION_OFF_AMAZON.video.map((el) =>
          mapKpi(el),
        ),
        online_video: mapGoalFormat.CONSIDERATION_OFF_AMAZON.online_video.map(
          (el) => mapKpi(el),
        ),
        streaming_tv: mapGoalFormat.CONSIDERATION_OFF_AMAZON.streaming_tv.map(
          (el) => mapKpi(el),
        ),
        prime_deals: mapGoalFormat.CONSIDERATION_OFF_AMAZON.streaming_tv.map(
          (el) => mapKpi(el),
        ),
        mobile: mapGoalFormat.CONSIDERATION_OFF_AMAZON.mobile.map((el) =>
          mapKpi(el),
        ),
      },
    },
    CONVERSION: {
      data: goalsMapped.conversion,
      kpis: {
        standard_display:
          mapGoalFormat.CONVERSION_OFF_AMAZON.standard_display.map((el) =>
            mapKpi(el),
          ),
        display: mapGoalFormat.CONVERSION_OFF_AMAZON.display.map((el) =>
          mapKpi(el),
        ),
        video: mapGoalFormat.CONVERSION_OFF_AMAZON.video.map((el) =>
          mapKpi(el),
        ),
        online_video: mapGoalFormat.CONVERSION_OFF_AMAZON.online_video.map(
          (el) => mapKpi(el),
        ),
        streaming_tv: mapGoalFormat.CONVERSION_OFF_AMAZON.streaming_tv.map(
          (el) => mapKpi(el),
        ),
        prime_deals: mapGoalFormat.CONVERSION_OFF_AMAZON.streaming_tv.map(
          (el) => mapKpi(el),
        ),
        mobile: mapGoalFormat.CONVERSION_OFF_AMAZON.mobile.map((el) =>
          mapKpi(el),
        ),
      },
    },
  },
  SOLD_ON_AMAZON: {
    AWARENESS: {
      data: goalsMapped.awareness,
      kpis: {
        standard_display: mapGoalFormat.AWARENESS.standard_display.map((el) =>
          mapKpi(el),
        ),
        display: mapGoalFormat.AWARENESS.display.map((el) => mapKpi(el)),
        video: mapGoalFormat.AWARENESS.video.map((el) => mapKpi(el)),
        online_video: mapGoalFormat.AWARENESS.online_video.map((el) =>
          mapKpi(el),
        ),
        streaming_tv: mapGoalFormat.AWARENESS.streaming_tv.map((el) =>
          mapKpi(el),
        ),
        prime_deals: mapGoalFormat.AWARENESS.streaming_tv.map((el) =>
          mapKpi(el),
        ),
        mobile: mapGoalFormat.AWARENESS.mobile.map((el) => mapKpi(el)),
      },
    },
    CONSIDERATION: {
      data: goalsMapped.consideration,
      kpis: {
        standard_display: mapGoalFormat.CONSIDERATION.standard_display.map(
          (el) => mapKpi(el),
        ),
        display: mapGoalFormat.CONSIDERATION.display.map((el) => mapKpi(el)),
        video: mapGoalFormat.CONSIDERATION.video.map((el) => mapKpi(el)),
        online_video: mapGoalFormat.CONSIDERATION.online_video.map((el) =>
          mapKpi(el),
        ),
        streaming_tv: mapGoalFormat.CONSIDERATION.streaming_tv.map((el) =>
          mapKpi(el),
        ),
        prime_deals: mapGoalFormat.CONSIDERATION.streaming_tv.map((el) =>
          mapKpi(el),
        ),
        mobile: mapGoalFormat.CONSIDERATION.mobile.map((el) => mapKpi(el)),
      },
    },
    CONVERSION: {
      data: goalsMapped.conversion,
      kpis: {
        standard_display: mapGoalFormat.CONVERSION.standard_display.map((el) =>
          mapKpi(el),
        ),
        display: mapGoalFormat.CONVERSION.display.map((el) => mapKpi(el)),
        video: mapGoalFormat.CONVERSION.video.map((el) => mapKpi(el)),
        online_video: mapGoalFormat.CONVERSION.online_video.map((el) =>
          mapKpi(el),
        ),
        streaming_tv: mapGoalFormat.CONVERSION.streaming_tv.map((el) =>
          mapKpi(el),
        ),
        prime_deals: mapGoalFormat.CONVERSION.streaming_tv.map((el) =>
          mapKpi(el),
        ),
        mobile: mapGoalFormat.CONVERSION.mobile.map((el) => mapKpi(el)),
      },
    },
  },
};

export const mapCampaignFormatToSupportedFormat = (
  campaignFormat: CampaignFormat,
): Format => {
  if (
    campaignFormat === "aap_mobile_app" ||
    campaignFormat === "amazon_mobile_display"
  ) {
    return "mobile";
  }

  return campaignFormat as Format;
};

export const mapStrategyFormatsToCampaignFormats = (
  formats: Format[],
): CampaignFormat[] => {
  const res: CampaignFormat[] = formats.filter(
    (f) => f !== "mobile",
  ) as CampaignFormat[];

  if (formats.includes("mobile")) {
    res.push("aap_mobile_app", "amazon_mobile_display");
  }

  return res;
};

export const getColumnsMerged = (
  baseColumnList: Column[],
  filterColumns: Column[],
  actionsColumns: Column[],
): Column[] => [...baseColumnList, ...filterColumns, ...actionsColumns];

export const useDuplicateStrategy = (): [
  UseMutationResult<AxiosResponse, AxiosError, string[], unknown>,
  (
    strategyIds: string[],
    onSuccess: (res: AxiosResponse) => void,
  ) => Promise<boolean>,
] => {
  const queryClient = useQueryClient();
  const mutation = useDuplicateStrategyMutation();
  return [
    mutation,
    async (strategyIds: string[], onSuccess: (res: AxiosResponse) => void) => {
      const result = await mutation.mutateAsync(strategyIds, {
        onError,
        onSuccess: (res) => {
          eventBus.openSnackbar(
            formatMessage("strategies.duplicate.success", {
              values: { count: res?.data.length },
            }),
            "success",
          );
          queryClient.resetQueries({
            queryKey: queriesNames["strategy-list"],
            exact: false,
          });
          onSuccess(res);
        },
      });
      return result?.status === 201;
    },
  ];
};

export const filterStrategyEligibleChannels = (
  channels: Channel[],
): StrategyEligibleChannel[] =>
  channels.filter((channel): channel is StrategyEligibleChannel =>
    (strategyEligibleChannelsList as readonly string[]).includes(channel),
  );

export const mapStrategyChannelToRoute: Record<
  StrategyEligibleChannel,
  string
> = {
  dsp: STRATEGY_CREATE_DSP,
  sponsored_products: STRATEGY_CREATE_SP,
};
