import { get, patch, post, put, remove } from "common/api";
import { SponsoredStrategyCreate as SponsoredStrategyCreatePayload } from "modules/api/models/SponsoredStrategyCreate";
import { SponsoredStrategyDraft } from "modules/api/models/SponsoredStrategyDraft";
import {
  AudienceSetPayload,
  AudienceSetResponse,
  AudienceTargetingMatchType,
} from "modules/Audiences/types";
import { AssetTargetType } from "modules/Creatives/types";
import {
  AdvancedCampaign,
  Strategy,
  StrategyCurrencyType,
  CreateStrategyPayload,
  CreateAdGroupPayload,
  CreateAdGroupResponse,
  CreateCampaignPayload,
  EditCampaignPayload,
  EditStrategyFormData,
  EditStrategyStatusFormData,
  Campaign,
  StrategyQueryParams,
  EditCampaignStatusFormData,
  SwapStrategySetPayload,
  ArchiveCampaignsFromStrategyPayload,
  ArchiveAdGroupsFromStrategyPayload,
  StrategyDSPDraft,
} from "modules/Strategies/types";

export const getCampaignsList = (id: string, params: StrategyQueryParams) =>
  get<Campaign[]>(`/strategies/${id}/campaigns/`, { params });

export const getStrategyAudienceSets = (
  id: string,
  params: StrategyQueryParams,
) => get(`/strategies/${id}/audience-sets/`, { params });

export const getStrategyAudienceSetChildren = (
  strategyId: string,
  id: string,
  params: StrategyQueryParams,
) => get(`/strategies/${strategyId}/audience-sets/${id}/metrics/`, { params });

export const replaceStrategyAudienceSet = ({
  strategyId,
  audienceId,
  payload,
}: {
  strategyId: string;
  audienceId: string;
  payload: AudienceSetPayload;
}) =>
  post<AudienceSetResponse, AudienceSetPayload>(
    `/strategies/${strategyId}/audience-sets/${audienceId}/replace_audience_set/`,
    payload,
  );

export const removeStrategyAudienceSet = ({
  strategyId,
  audienceId,
}: {
  strategyId: string;
  audienceId: string;
}) => remove(`/strategies/${strategyId}/audience-sets/${audienceId}/`);

export const getStrategySummary = (
  id: string,
  params?: { metrics_date_range?: string },
) =>
  get<Strategy>(`/strategies/${id}/`, {
    params: { ...params, include_archived: true, currency_type: "primary" },
  });

export const getStrategyTargetTypes = (id: string) =>
  get<{ target_types: AssetTargetType[] }>(
    `/strategies/${id}/assets_target_types/`,
  );

export const createStrategyDSP = (payload: CreateStrategyPayload) =>
  post("/strategies/", payload);

export const createStrategySP = (payload: SponsoredStrategyCreatePayload) =>
  post("/strategies-sp/", payload);

export const deleteStrategy = (id: string) => remove(`/strategies/${id}/`);

export const updateStrategy = (data: {
  id: string;
  payload: Omit<Partial<EditStrategyFormData>, "asin_numbers"> & {
    asin_numbers?: string[];
  };
}) => patch<Strategy>(`/strategies/${data.id}/`, data.payload);

export const duplicateStrategy = (payload: string[]) =>
  post("/strategies/duplicate/", { object_ids: payload });

export const getStrategyDSPDraft = (id: string) =>
  get<StrategyDSPDraft>(`/strategies/draft/${id}/`);

export const createStrategyDSPDraft = (
  payload: Partial<CreateStrategyPayload>,
) => post("/strategies/draft/", payload);

export const deleteStrategyDSPDraft = (id: string) =>
  remove(`/strategies/draft/${id}/`);

export const updateStrategyDSPDraft = (data: {
  id: string;
  payload: Partial<CreateStrategyPayload>;
}) => put<Strategy>(`/strategies/draft/${data.id}/`, data.payload);

export const getStrategySPDraft = (id: string) =>
  get<SponsoredStrategyDraft>(`/strategies-sp/draft/${id}/`);

export const createStrategySPDraft = (payload: SponsoredStrategyDraft) =>
  post("/strategies-sp/draft/", payload);

export const deleteStrategySPDraft = (id: string) =>
  remove(`/strategies-sp/draft/${id}/`);

export const updateStrategySPDraft = (data: {
  id: string;
  payload: SponsoredStrategyDraft;
}) => put<Strategy>(`/strategies-sp/draft/${data.id}/`, data.payload);

export const updateStrategyStatus = ({
  payload,
  id,
}: {
  payload: EditStrategyStatusFormData;
  id?: string;
}) => post(`/strategies/${id}/set_status/`, payload);

export const exportStrategy = (id: string) =>
  get(`/strategies/${id}/export/`, { responseType: "blob" });

export const getAdvancedCampaign = (
  campaignId: string,
  params: { currency_type: StrategyCurrencyType; metrics_date_range?: string },
  strategyId?: string,
) =>
  get<AdvancedCampaign>(`/strategies/${strategyId}/campaigns/${campaignId}/`, {
    params,
  });

export const createAdGroup = (data: {
  payload: CreateAdGroupPayload;
  strategyId?: string;
  currencyType: StrategyCurrencyType;
}) =>
  post<CreateAdGroupResponse>(
    `/strategies/${data.strategyId}/ad-groups/?currency_type=${data.currencyType}`,
    data.payload,
  );

export const createCampaign = ({
  strategyId,
  payload,
  currencyType,
}: {
  strategyId: string;
  payload: CreateCampaignPayload;
  currencyType: StrategyCurrencyType;
}) =>
  post(
    `/strategies/${strategyId}/campaigns/?currency_type=${currencyType}`,
    payload,
  );

export const editCampaign = ({
  strategyId,
  campaignId,
  payload,
  currencyType,
}: {
  strategyId: string;
  campaignId: string;
  payload: EditCampaignPayload;
  currencyType: StrategyCurrencyType;
}) =>
  patch(`/strategies/${strategyId}/campaigns/${campaignId}/`, payload, {
    params: { currency_type: currencyType },
  });

export const updateCampaignStatus = ({
  strategyId,
  campaignId,
  payload,
}: {
  strategyId: string;
  campaignId: string;
  payload: EditCampaignStatusFormData;
}) =>
  post(
    `/strategies/${strategyId}/campaigns/${campaignId}/set_status/`,
    payload,
  );

export const updateStrategyAudienceSets = ({
  strategyId,
  payload,
}: {
  strategyId: string;
  payload: {
    audience_sets: string[];
    audience_targeting_match_type: AudienceTargetingMatchType;
  };
}) =>
  post(
    `/strategies/${strategyId}/audience-sets/change_campaign_audience_sets/`,
    payload,
  );

export const swapAudienceSet = ({
  strategyId,
  oldAudienceSetId,
  payload,
}: {
  strategyId: string;
  oldAudienceSetId: string;
  payload: SwapStrategySetPayload;
}) =>
  patch(
    `/strategies/${strategyId}/audience-sets/${oldAudienceSetId}/swap_audience_set/`,
    payload,
  );

export const archiveCampaignsFromStrategy = (data: {
  strategyId: string;
  data: ArchiveCampaignsFromStrategyPayload;
}) => post(`/strategies/${data.strategyId}/campaigns/archive/`, data.data);

export const archiveAdGroupsFromStrategy = (data: {
  strategyId: string;
  data: ArchiveAdGroupsFromStrategyPayload;
}) => post(`/strategies/${data.strategyId}/ad-groups/archive/`, data.data);

export const unarchiveCampaignsFromStrategy = (data: {
  strategyId: string;
  data: ArchiveCampaignsFromStrategyPayload;
}) =>
  post(`/strategies/${data.strategyId}/campaigns/unarchive/`, { ...data.data });

export const unarchiveAdGroup = (data: {
  strategyId: string;
  adGroupId: string;
}) =>
  post(`/strategies/${data.strategyId}/ad-groups/unarchive/`, {
    object_ids: [data.adGroupId],
  });
