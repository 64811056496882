import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";

import { AnyObject } from "common/types";
import {
  AudienceSetPayload,
  AudienceTargetingMatchType,
} from "modules/Audiences/types";
import {
  EditCampaignFlightResponse,
  EditFlightMarketBudgetResponse,
  StrategyFlightRange,
  StrategyFlightRangeAdd,
} from "modules/Strategies/SingleStrategy/tabs/SingleStrategyPlanner/types";
import {
  ActivationStatus,
  ArchiveAdGroupsFromStrategyPayload,
  ArchiveCampaignsFromStrategyPayload,
  StrategyCurrencyType,
  Strategy,
  CreateStrategyPayload,
  CreateStrategyResponse,
  CreateStrategyResponseError,
  CreateAdGroupPayload,
  CreateAdGroupResponse,
  CreateCampaignPayload,
  CreateCampaignResponse,
  CreateCampaignResponseError,
  EditCampaignPayload,
  EditCampaignResponse,
  EditCampaignResponseError,
  EditStrategyFormData,
  EditStrategyStatusFormData,
  EditStrategyCreativePayload,
  UpdateCampaignCreativePayload,
  SwapStrategySetPayload,
  EditCampaignStatusFormData,
} from "modules/Strategies/types";

import { SponsoredStrategyCreate as SponsoredStrategyCreatePayload } from "../models/SponsoredStrategyCreate";
import { SponsoredStrategyDraft as SponsoredStrategyDraftPayload } from "../models/SponsoredStrategyDraft";
import {
  addFlightRange,
  deleteFlightRange,
  editCampaignFlight,
  EditCapaignFlightRequestParams,
  editFlightMarketBudget,
  EditFlightMarketBudgetRequestParams,
  editFlightRange,
} from "../requests/strategies/planner";
import { setAdGroupStatus } from "../requests/strategies/strategyCampaign";
import {
  editStrategyCreatives,
  removeStrategyCreative,
  updateStrategyCreative,
} from "../requests/strategies/strategyCreatives";
import {
  archiveAdGroupsFromStrategy,
  archiveCampaignsFromStrategy,
  createAdGroup,
  createCampaign,
  createStrategyDSP,
  createStrategyDSPDraft,
  createStrategySP,
  createStrategySPDraft,
  deleteStrategy,
  deleteStrategyDSPDraft,
  deleteStrategySPDraft,
  duplicateStrategy,
  editCampaign,
  removeStrategyAudienceSet,
  replaceStrategyAudienceSet,
  swapAudienceSet,
  unarchiveAdGroup,
  unarchiveCampaignsFromStrategy,
  updateCampaignStatus,
  updateStrategy,
  updateStrategyAudienceSets,
  updateStrategyDSPDraft,
  updateStrategySPDraft,
  updateStrategyStatus,
} from "../requests/strategies/strategyDetail";

export const useCreateStrategyDSPMutation = () => {
  const mutation = useMutation<
    AxiosResponse<CreateStrategyResponse>,
    AxiosError<CreateStrategyResponseError>,
    CreateStrategyPayload
  >(createStrategyDSP);
  return mutation;
};

export const useCreateStrategySPMutation = () => {
  const mutation = useMutation<
    AxiosResponse<CreateStrategyResponse>,
    AxiosError<CreateStrategyResponseError>,
    SponsoredStrategyCreatePayload
  >(createStrategySP);
  return mutation;
};

export const useUpdateStrategyMutation = () => {
  const mutation = useMutation<
    AxiosResponse<Strategy>,
    AxiosError,
    {
      id: string;
      payload: Omit<Partial<EditStrategyFormData>, "asin_numbers"> & {
        asin_numbers?: string[];
      };
    }
  >(updateStrategy);
  return mutation;
};

export const useDuplicateStrategyMutation = () => {
  const mutation = useMutation<AxiosResponse, AxiosError, string[]>(
    duplicateStrategy,
  );
  return mutation;
};

export const useCreateStrategyDSPDraftMutation = () => {
  const mutation = useMutation<
    AxiosResponse<CreateStrategyResponse>,
    AxiosError<CreateStrategyResponseError>,
    Partial<CreateStrategyPayload>
  >(createStrategyDSPDraft);
  return mutation;
};

export const useUpdateStrategyDSPDraftMutation = () => {
  const mutation = useMutation<
    AxiosResponse<Strategy>,
    AxiosError,
    {
      id: string;
      payload: Partial<CreateStrategyPayload>;
    }
  >(updateStrategyDSPDraft);
  return mutation;
};

export const useDeleteStrategyDSPDraftMutation = () => {
  const mutation = useMutation<AxiosResponse<Strategy>, AxiosError, string>(
    deleteStrategyDSPDraft,
  );
  return mutation;
};

export const useCreateStrategySPDraftMutation = () => {
  const mutation = useMutation<
    AxiosResponse<CreateStrategyResponse>,
    AxiosError<CreateStrategyResponseError>,
    SponsoredStrategyDraftPayload
  >(createStrategySPDraft);
  return mutation;
};

export const useUpdateStrategySPDraftMutation = () => {
  const mutation = useMutation<
    AxiosResponse<Strategy>,
    AxiosError,
    {
      id: string;
      payload: SponsoredStrategyDraftPayload;
    }
  >(updateStrategySPDraft);
  return mutation;
};

export const useDeleteStrategySPDraftMutation = () => {
  const mutation = useMutation<AxiosResponse<Strategy>, AxiosError, string>(
    deleteStrategySPDraft,
  );
  return mutation;
};

export const useUpdateStrategyStatusMutation = () => {
  const mutation = useMutation<
    AxiosResponse<Strategy>,
    AxiosError,
    {
      id: string;
      payload: EditStrategyStatusFormData;
    }
  >(updateStrategyStatus);
  return mutation;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DeleteStrategyResponseError = any;

export const useDeleteStrategyMutation = () => {
  const mutation = useMutation<
    AxiosResponse,
    AxiosError<DeleteStrategyResponseError>,
    string
  >(deleteStrategy);
  return mutation;
};

export const useCreateAdGroupMutation = () => {
  const mutation = useMutation<
    AxiosResponse<CreateAdGroupResponse>,
    AxiosError,
    {
      payload: CreateAdGroupPayload;
      strategyId?: string;
      currencyType: StrategyCurrencyType;
    }
  >(createAdGroup);
  return mutation;
};

export const useArchiveStrategyCampaignsMutation = () => {
  const mutation = useMutation<
    AxiosResponse,
    AxiosError,
    { strategyId: string; data: ArchiveCampaignsFromStrategyPayload }
  >(archiveCampaignsFromStrategy);
  return mutation;
};

export const useUnarchiveStrategyCampaignsMutation = () => {
  const mutation = useMutation<
    AxiosResponse,
    AxiosError,
    { strategyId: string; data: ArchiveCampaignsFromStrategyPayload }
  >(unarchiveCampaignsFromStrategy);
  return mutation;
};

export const useArchiveStrategyAdGroupsMutation = () => {
  const mutation = useMutation<
    AxiosResponse,
    AxiosError,
    { strategyId: string; data: ArchiveAdGroupsFromStrategyPayload }
  >(archiveAdGroupsFromStrategy);
  return mutation;
};

export const useUnarchiveAdGroupMutation = () => {
  const mutation = useMutation<
    AxiosResponse,
    AxiosError,
    { strategyId: string; adGroupId: string }
  >(unarchiveAdGroup);
  return mutation;
};

export const useCreateCampaignMutation = () => {
  const mutation = useMutation<
    AxiosResponse<CreateCampaignResponse>,
    AxiosError<CreateCampaignResponseError>,
    {
      strategyId: string;
      payload: CreateCampaignPayload;
      currencyType: StrategyCurrencyType;
    }
  >(createCampaign);
  return mutation;
};

export const useEditCampaignMutation = () => {
  const mutation = useMutation<
    AxiosResponse<EditCampaignResponse>,
    AxiosError<EditCampaignResponseError>,
    {
      strategyId: string;
      campaignId: string;
      payload: EditCampaignPayload;
      currencyType: StrategyCurrencyType;
    }
  >(editCampaign);
  return mutation;
};

export const useSetLIStatus = () => {
  const mutation = useMutation<
    AxiosResponse,
    AxiosError,
    {
      strategyId: string;
      adGroupId: string;
      payload: { status: ActivationStatus };
    }
  >(setAdGroupStatus);
  return mutation;
};

export const useSetStrategyStatusMutation = () => {
  const mutation = useMutation<
    AxiosResponse<EditStrategyStatusFormData>,
    AxiosError<AnyObject>,
    { id?: string; payload: EditStrategyStatusFormData }
  >(updateStrategyStatus);
  return mutation;
};

export const useUpdateStrategyAudienceSetsMutation = () => {
  const mutation = useMutation<
    AxiosResponse,
    AxiosError,
    {
      strategyId: string;
      payload: {
        audience_sets: string[];
        audience_targeting_match_type: AudienceTargetingMatchType;
      };
    }
  >(updateStrategyAudienceSets);
  return mutation;
};

export const useReplaceStrategyAudienceSetMutation = () => {
  const mutation = useMutation<
    AxiosResponse,
    AxiosError,
    {
      strategyId: string;
      audienceId: string;
      payload: AudienceSetPayload;
    }
  >(replaceStrategyAudienceSet);
  return mutation;
};

export const useRemoveStrategyAudienceSetMutation = () => {
  const mutation = useMutation<
    AxiosResponse,
    AxiosError<AnyObject>,
    {
      strategyId: string;
      audienceId: string;
    }
  >(removeStrategyAudienceSet);
  return mutation;
};

export const useStrategyCreativesEditMutation = () => {
  const mutation = useMutation<
    AxiosResponse<EditStrategyCreativePayload>,
    AxiosError<AnyObject>,
    { payload: EditStrategyCreativePayload; id: string }
  >(editStrategyCreatives);
  return mutation;
};

export const useStrategyCreativeUpdateMutation = () => {
  const mutation = useMutation<
    AxiosResponse<UpdateCampaignCreativePayload>,
    AxiosError<AnyObject>,
    {
      payload: UpdateCampaignCreativePayload;
      strategyId: string;
      creativeId: string;
    }
  >(updateStrategyCreative);
  return mutation;
};

export const useRemoveStrategyCreativeMutation = () => {
  const mutation = useMutation<
    AxiosResponse,
    AxiosError<AnyObject>,
    {
      strategyId: string;
      creativeId: string;
    }
  >(removeStrategyCreative);
  return mutation;
};

export const useStrategySetSwapMutation = () => {
  const mutation = useMutation<
    AxiosResponse<SwapStrategySetPayload>,
    AxiosError<AnyObject>,
    {
      payload: SwapStrategySetPayload;
      strategyId: string;
      oldAudienceSetId: string;
    }
  >(swapAudienceSet);
  return mutation;
};

export const useSetCampaignStatusMutation = () => {
  const mutation = useMutation<
    AxiosResponse<EditStrategyStatusFormData>,
    AxiosError<AnyObject>,
    {
      strategyId: string;
      campaignId: string;
      payload: EditCampaignStatusFormData;
    }
  >(updateCampaignStatus);
  return mutation;
};

export const useAddFlightRangeMutation = () => {
  const mutation = useMutation<
    AxiosResponse<StrategyFlightRange>,
    AxiosError,
    {
      strategyId: string;
      payload: StrategyFlightRangeAdd;
    }
  >(addFlightRange);

  return mutation;
};

export const useEditFlightRangeMutation = () => {
  const mutation = useMutation<
    AxiosResponse<StrategyFlightRange>,
    AxiosError,
    {
      strategyId: string;
      flightRangeId: string;
      payload: Partial<StrategyFlightRangeAdd>;
    }
  >(editFlightRange);

  return mutation;
};

export const useEditCampaignFlightMutation = () => {
  const mutation = useMutation<
    AxiosResponse<EditCampaignFlightResponse>,
    AxiosError,
    EditCapaignFlightRequestParams
  >(editCampaignFlight);

  return mutation;
};

export const useEditFlightMarketBudgetMutation = () => {
  const mutation = useMutation<
    AxiosResponse<EditFlightMarketBudgetResponse>,
    AxiosError,
    EditFlightMarketBudgetRequestParams
  >(editFlightMarketBudget);

  return mutation;
};

export const useDeleteFlightRangeMutation = () => {
  const mutation = useMutation<
    AxiosResponse,
    AxiosError,
    { strategyId: string; flightRangeId: string }
  >(deleteFlightRange);

  return mutation;
};
