import classNames from "classnames";
import { useContext } from "react";

import { Icon, IconName } from "common/components/Icon";

import { PopoverContext } from "../../context";

export type RefProps = {
  buttonText: string;
  testId: string;
  trailingComponents: JSX.Element[];
  iconName?: IconName;
  additionalButtonStylings?: string;
  additionalButtonTextStylings?: string;
  isError?: boolean;
  outline?: boolean;
  disabled?: boolean;
};

export const LabelPopoverRef: React.FC<RefProps> = ({
  testId,
  buttonText,
  iconName,
  trailingComponents,
  additionalButtonStylings,
  additionalButtonTextStylings,
  isError = false,
  outline = false,
  disabled = false,
}) => {
  const { isOpen } = useContext(PopoverContext);

  return (
    <div
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      className={classNames(
        `${additionalButtonStylings} input flex items-center justify-between gap-x-1`,
        {
          "hover:bg-white-550": !isOpen,
          "border-blue-500 bg-white-600": isOpen && outline,
          "border-red-100": isError,
          "border-gray-750 text-gray-750": disabled,
        },
      )}
    >
      {iconName && <Icon name={iconName} size="small" />}
      <span className={`${additionalButtonTextStylings} grow text-left`}>
        {buttonText}
      </span>
      <div
        className="flex justify-end items-center gap-x-1"
        data-testid={testId}
      >
        {trailingComponents}
        <div className="text-inherit">
          <Icon name={isOpen ? "caretUp" : "caretDown"} size="small" />
        </div>
      </div>
    </div>
  );
};
