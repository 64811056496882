export const colors = {
  transparent: "transparent",
  current: "currentColor",
  black: {
    DEFAULT: "#000000",
    100: "#333333",
  },
  white: {
    DEFAULT: "#FFF",
    "t-50": "#FFFFFF80",
    0: "#FFF",
    10: "#EFF3FF",
    20: "#F5F8FF",
    30: "#D7D7D7",
    40: "#FCFCFC",
    50: "#FBFBFF",
    60: "#F9F9F9",
    70: "#FDFDFD",
    80: "#F4F5FC",
    100: "#E7E7E7",
    150: "#FCFDFF",
    200: "#F6F6F6",
    250: "#E4E8F0",
    300: "#F8F9FD",
    350: "#F8F9FF",
    400: "#F8F8F8",
    450: "#F0F0F0",
    500: "#F4F4F4",
    550: "#FBFBFB",
    600: {
      DEFAULT: "#EAEDFB",
      "t-30": "#EAEDFB4D",
    },
    601: "#eff2ff",
    650: "#F6F8FF",
    700: "#EAEEFF",
    750: "#EBEBEB",
    800: {
      DEFAULT: "#EFEFEF",
      "t-20": "#EFEFEF33",
    },
    900: "#DBE2F9",
  },
  red: {
    10: "#FFECEC",
    20: "#FFE3E3",
    44: "#FFEFEF",
    100: {
      DEFAULT: "#F5626C",
      "t-10": "#f5626c1a",
    },
    150: "#FDE3E3",
    200: "#FFA8A8",
    250: "#CC4A1A",
    300: "#FFD7E8",
    400: "#FFF4FC",
    450: "#FFDEF6",
    500: "#D14836",
    600: "#FFF3F3",
    650: "#6B384E",
    700: "#FFDEF6",
    800: "#FFF3FC",
    900: "#F8535E",
  },
  blue: {
    10: "#4765FF",
    15: "#F2FAFF",
    16: "#ECF8FF",
    17: "#F5FBFF",
    18: "#F8FCFF",
    19: "#58D5FA",
    20: "#E7F6FF",
    21: "#165BAA",
    22: "#CDD8FF",
    23: "#87E4FF",
    24: "#E0E4FF",
    25: "#4760FF",
    26: "#5E70CC",
    27: "#172F89",
    28: "#128FB8",
    30: "#D9F1FF",
    40: "#172B71",
    50: "#A3B1E4",
    60: "#F2F4FC",
    69: "#C7DAFF",
    70: "#F9FAFE",
    80: "#678FFF",
    90: "#6A9DFF",
    100: "#D4DCF9",
    110: "#6D85FF",
    120: "#CAE3FF",
    130: "#2841A7",
    150: "#F3F5FF",
    160: "#7C97F9",
    200: "#284097",
    250: "#DDF2FE",
    300: {
      DEFAULT: "#4164E2",
      "t-35": "#4164e259",
    },
    325: "#D6DEFF",
    350: "#3756C8",
    400: "#4E728D",
    450: "#4466E4",
    500: "#8CA2F3",
    550: "#D6DFFF",
    600: {
      DEFAULT: "#20A3A9",
      "t-20": "#20a3a966",
    },
    650: "#EFF2FF",
    700: "#3E5286",
    750: "#2F4BB1",
    800: {
      DEFAULT: "#11224D",
      "t-50": "#11224D80",
    },
    850: "#005976",
    870: "#3B525F",
    900: {
      DEFAULT: "#132D90",
      "t-80": "#2B429B",
    },
    950: "#142A81",
  },
  gray: {
    10: "#EDF0F9",
    20: "#F7F8FE",
    30: "#9FAAD3",
    40: "#EFF1FC",
    50: {
      DEFAULT: "#FCFDFF",
      "t-60": "#FCFDFF99",
    },
    60: "#DDDDDD",
    70: "#ADADAD",
    100: "#F8F9FD",
    200: "#FAFAFA",
    300: "#AFB6CA",
    400: "#838383",
    450: "#C5D8E8",
    500: "#F3F5FF",
    550: "#A7B2E7",
    600: {
      DEFAULT: "#6F6F6F",
      "t-20": "#6F6F6F33",
    },
    700: "#CFD4E8",
    750: "#CFD1D7",
    800: "#8890A6",
    900: {
      DEAFULT: "#131921",
      "t-50": "#13192180",
      "t-20": "#13192133",
    },
  },
  yellow: {
    50: "#F0E8CA",
    100: "#FFF8CA",
    200: "#FFFFDF",
    300: "#E8C23D",
    400: "#B78F01",
    500: "#FFD84D",
    600: "#FFEEAD",
    700: "#ECCF81",
  },
  orange: {
    300: "#EDBB80",
    400: "#FF720C",
  },
  pink: {
    50: "#FFA8A8",
    100: "#FF7BB3",
    200: "#C8079D",
    300: "#F765A3",
    400: "#E961A2",
    500: "#FF7EB5",
    600: "#D81F77",
    800: "#F6E0FF",
  },
  green: {
    10: "#F4FCF0",
    11: "#F5FBF2",
    12: "#F9FDF7",
    20: "#D9FFDB",
    30: "#E7FFE7",
    40: "#A4DC79",
    50: "#EFFFE2",
    60: "#EDF8E8",
    80: "E0FFE7",
    90: "#3E9F14",
    100: "#EBFFE4",
    200: "#DBF1DE",
    300: "#C5E4E8",
    400: "#228179",
    500: "#77AB60",
    600: "#BAEABF",
    700: "#588C30",
    800: "#3D5340",
    900: "#1A766F",
  },
  violet: {
    200: "#E2DBF1",
    300: "#D9D7F2",
    400: "#945ea7",
    500: "#431390",
    550: "#36097E",
    800: "#A557A0",
  },
  cream: {
    300: "#FFF4EC",
    400: "#FFEAE3",
  },
  brown: {
    800: "#5D4D3E",
  },
};
