/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { IconWeight } from "@phosphor-icons/react";
import classNames from "classnames";
import kebabCase from "lodash/kebabCase";
import React, { useState } from "react";

import { Icon, IconName, IconSize } from "../Icon";
import { Loader } from "../Loader";
import { VOWSkeleton } from "../VOWSkeleton";

export type ButtonProps = {
  onClick?: any;
  disabled?: boolean;
  loading?: boolean;
  loaderSize?: number;
  loaderWidth?: number;
  size?: "small" | "medium" | "xlarge" | "";
  type?: "button" | "submit" | "reset" | undefined;
  additionalClasses?: string;
  childrenClasses?: string;
  iconName?: IconName;
  iconSize?: IconSize;
  iconWeight?: IconWeight;
  styling?:
    | "blue-button"
    | "blue-outline-button"
    | "red-outline-button"
    | "plain-button"
    | "plain-outline-button"
    | "transparent-outline-button"
    | "";
  withSkeleton?: boolean;
  alt?: string;
  dataTestId: string;
  dataState?: string;
};

export const Button: React.FC<React.PropsWithChildren & ButtonProps> = ({
  children,
  type,
  onClick,
  disabled,
  additionalClasses,
  childrenClasses,
  styling = "",
  iconName,
  loading = false,
  loaderSize,
  loaderWidth,
  size = "medium",
  iconSize = "medium",
  iconWeight = "regular",
  withSkeleton = false,
  alt,
  dataTestId,
  dataState,
}) => {
  const [processingOnClick, setProcessingOnClick] = useState(false);

  const handleOnClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    setProcessingOnClick(true);
    try {
      if (onClick) await onClick(e);
    } finally {
      setProcessingOnClick(false);
    }
  };

  return (
    <>
      {withSkeleton ? (
        <VOWSkeleton
          containerClassName={`${additionalClasses} w-[100px]`}
          height={34}
        />
      ) : (
        <button
          className={classNames(
            "relative text-14 transition-colors duration-300 rounded-[5px] flex items-center justify-center gap-x-2",
            additionalClasses,
            styling,
            size,
            { "cursor-not-allowed": disabled },
          )}
          onClick={handleOnClick}
          disabled={disabled || processingOnClick || loading}
          type={type}
          data-testid={`${kebabCase(dataTestId)}-button`}
          data-state={dataState}
        >
          <>
            <div className="flex absolute">
              <Loader size={loaderSize} width={loaderWidth} loading={loading} />
            </div>
            {iconName && (
              <Icon
                name={iconName}
                size={iconSize}
                weight={iconWeight}
                alt={alt}
                className={classNames({
                  invisible: loading,
                })}
              />
            )}
            {children && (
              <div
                className={classNames(
                  `first-letter:capitalize ${childrenClasses}`,
                  {
                    invisible: loading,
                  },
                )}
              >
                {children}
              </div>
            )}
          </>
        </button>
      )}
    </>
  );
};
