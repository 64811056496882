/* eslint sort-keys-plus/sort-keys: "error" */
/* eslint-env es6 */

export const queriesNames = {
  "admin-group-list": "admin-group-list",
  "advertiser-ad-tags": "advertiser-ad-tags",
  "advertiser-channels": "advertiser-channels",
  "advertiser-details": "advertiser-details",
  "advertiser-industries": "advertiser-industries",
  "advertisers-list": "advertisers-list",
  "amazon-ad-tag-definitions": "amazon-ad-tag-definitions",
  "amazon-ad-tags": "amazon-ad-tags",
  "asset-list": "asset-list",
  "asset-list-infinite": "asset-list-infinite",
  "audience-create": "audience-create",
  "audience-edit": "audience-edit",
  "audience-set-strategy-list": "audience-set-strategy-list",
  "audience-set-strategy-list-infinite": "audience-set-strategy-list-infinite",
  "audience-sets": "audience-sets",
  "audience-sets-infinite": "audience-sets-infinite",
  "audiences-list": "audiences-list",
  "campaign-summary": "campaign-summary",
  "campaigns-list": "campaigns-list",
  "conversion-definitions": "conversion-definitions",
  "creatives-list": "creatives-list",
  credits: "credits",
  "credits-summary": "credits-summary",
  deals: "deals",
  "export-ad-groups": "export-ad-groups",
  "export-campaigns": "export-campaigns",
  "export-creatives": "export-creatives",
  "export-inventory": "export-inventory",
  "export-strategy": "export-strategy",
  "flight-ranges": "flight-ranges",
  "flight-ranges-short-info": "flight-ranges-short-info",
  "get-asset": "get-asset",
  "get-asset-campaigns": "get-asset-campaigns",
  "get-asset-failures": "get-asset-failures",
  "get-asset-metrics": "get-asset-metrics",
  "get-asset-strategy-names": "get-asset-strategy-names",
  "get-rec": "get-rec",
  "get-rec-performance": "get-rec-performance",
  "get-rec-strategy-names": "get-rec-strategy-names",
  "global-messages": "global-messages",
  "market-has-asset": "market-has-asset",
  "market-has-audience-set": "market-has-audience-set",
  "market-has-rec": "market-has-rec",
  "metrics-graph": "metrics-graph",
  "overlapping-audiences-list": "overlapping-audiences-list",
  "performance-metrics": "performance-metrics",
  "product-audiences": "product-audiences",
  "profile-account": "profile-account",
  "rates-list": "rates-list",
  "rec-creative-preview": "rec-creative-preview",
  "rec-creatives-list": "rec-creatives-list",
  "rec-creatives-list-infinite": "rec-creatives-list-infinite",
  "reporting-ad-groups": "reporting-ad-groups",
  "reporting-ad-groups-aggregated": "reporting-ad-groups-aggregated",
  "reporting-ad-groups-performance-stats":
    "reporting-ad-groups-performance-stats",
  "reporting-campaigns": "reporting-campaigns",
  "reporting-campaigns-aggregated": "reporting-campaigns-aggregated",
  "reporting-campaigns-performance-stats":
    "reporting-campaigns-performance-stats",
  "reporting-channel-spend": "reporting-channel-spend",
  "reporting-creatives": "reporting-creatives",
  "reporting-current-campaigns": "reporting-current-campaigns",
  "reporting-inventory": "reporting-inventory",
  "reporting-inventory-aggregated": "reporting-inventory-aggregated",
  "reporting-inventory-subrows": "reporting-inventory-subrows",
  "reporting-performance-stats": "reporting-performance-stats",
  "reporting-top-ad-groups": "reporting-top-ad-groups",
  "reporting-top-channels": "reporting-top-channels",
  "reporting-user-preference-list": "reporting-user-preference-list",
  "single-audience-set": "single-audience-set",
  "single-product-audience": "single-product-audience",
  "strategy-audience-sets-children-list":
    "strategy-audience-sets-children-list",
  "strategy-audience-sets-list": "strategy-audience-sets-list",
  "strategy-audience-sets-list-no-pagination":
    "strategy-audience-sets-list-no-pagination",
  "strategy-campaign": "strategy-campaign",
  "strategy-campaign-ad-group-groups": "strategy-campaign-ad-group-groups",
  "strategy-campaign-ad-groups": "strategy-campaign-ad-groups",
  "strategy-changesets": "strategy-changesets",
  "strategy-creatives-list": "strategy-creatives-list",
  "strategy-creatives-list-infinite": "strategy-creatives-list-infinite",
  "strategy-draft": "strategy-draft",
  "strategy-history-chart": "strategy-history-chart",
  "strategy-line-item": "strategy-line-item",
  "strategy-list": "strategy-list",
  "strategy-summary": "strategy-summary",
  "strategy-target-types": "strategy-target-types",
  "strategy-targeting-categories": "strategy-targeting-categories",
  "strategy-targeting-keywords": "strategy-targeting-keywords",
  "strategy-targeting-products": "strategy-targeting-products",
  "targeting-categories": "targeting-categories",
  "targeting-fees": "targeting-fees",
  "targeting-products": "targeting-products",
  "targeting-products-infinite": "targeting-products-infinite",
  "targeting-summary": "targeting-summary",
  "user-countries": "user-countries",
  "user-list": "user-list",
};
