import { endOfDay, isAfter, isBefore, isSameDay, isValid } from "date-fns";
import parsePhoneNumber, { CountryCode } from "libphonenumber-js";

import {
  DEFAULT_BACKEND_DATE_FORMAT,
  DEFAULT_DATE_FORMAT,
} from "common/constants";
import { formatMessage } from "common/internationalization";
import { Country, DateRange } from "common/types";
import { processAsinCSV } from "common/utils/asins";
import { getLocaleDateFormat } from "common/utils/dateRange";
import { format, parse } from "common/utils/dates";
import { formatNumericValue } from "common/utils/formatNumericValue";
import {
  alphanumeric,
  exactLettersNumber,
  integersOnly,
  startsWithLettersNumber,
  threeDecimalPlaces,
  twoDecimalPlaces,
} from "common/utils/regex";
import { MAX_MONEY_VALUE } from "modules/Strategies/utils";

export const validateOnlyIntegersAllowed = (value: string, isRequired = true) =>
  !isRequired ||
  integersOnly.test(value) ||
  formatMessage("common.error.onlyIntegerValuesAreAllowed");

export const validateMinValue = (
  value: number,
  messageKey = "common.error.minimumValueIs",
) => ({
  value,
  message: formatMessage(messageKey, { values: { value } }),
});

export const validateMaxValue = (value = MAX_MONEY_VALUE) => ({
  value,
  message: formatMessage("common.error.maximumValueIs", {
    values: {
      value: formatNumericValue("number", value),
    },
  }),
});

export const validateMaxLength = (value: number) => ({
  value,
  message: formatMessage("common.error.input.validation.maxLength", {
    values: { value },
  }),
});

// if isNaN input must be empty so it's handled by `required` option
export const validateMaxTwoDecimalPlaces = (value: string | number) =>
  Number.isNaN(value) ||
  !value ||
  twoDecimalPlaces.test(value.toString()) ||
  formatMessage("common.error.noMoreThanTwoDecimalPlaces");

export const validateMaxThreeDecimalPlaces = (value: string | number) =>
  Number.isNaN(value) ||
  !value ||
  threeDecimalPlaces.test(value.toString()) ||
  formatMessage("common.error.noMoreThanThreeDecimalPlaces");

export const validateExactNumber = (value: number, expected: number[]) =>
  Number.isNaN(value) ||
  expected.includes(value) ||
  formatMessage("common.error.noMatchExpectedValue", {
    values: {
      expected: expected.join(", "),
    },
  });

export const validateAGratherThanBValues = (
  valueA: number,
  valueB: number,
  messageKey = "common.error.AGreaterThanB",
) =>
  Number.isNaN(valueA) ||
  Number.isNaN(valueB) ||
  !valueA ||
  !valueB ||
  valueA >= valueB ||
  formatMessage(messageKey, {
    values: {
      valueA,
      valueB,
    },
  });

export const validateDatesInFuture = (value: DateRange) =>
  isBefore(
    new Date(format(new Date(), DEFAULT_BACKEND_DATE_FORMAT)),
    endOfDay(value.lower),
  ) || formatMessage("common.error.input.validation.futureDates");

export const validateDatePattern = (value: string) => {
  try {
    if (!value) {
      return;
    }
    const parsedDate = parse(value, DEFAULT_DATE_FORMAT, new Date(), {
      withoutFallback: true,
      silent: true,
    });
    if (!isValid(parsedDate)) {
      const properFormat = getLocaleDateFormat().replace(
        /(\W|^)(y)(\W|$)/,
        "$1yyyy$3",
      ); // replace y with yyyy
      return formatMessage(
        "common.components.datePicker.error.invalidDateFormat",
        { values: { format: properFormat } },
      );
    }
  } catch {
    const properFormat = getLocaleDateFormat();
    return formatMessage(
      "common.components.datePicker.error.invalidDateFormat",
      { values: { format: properFormat } },
    );
  }
};

export const readonlyStartDate = (
  currentStartDate: Date,
  initialStartDate: Date,
  object: "Campaign" | "AdGroup",
) =>
  !isBefore(initialStartDate, Date.now()) ||
  isSameDay(currentStartDate, initialStartDate) ||
  isAfter(currentStartDate, initialStartDate) ||
  formatMessage(`common.error.cannotModify${object}StardDate`);

export const validatePhoneNumber = (
  number: string,
  dial: string,
  country: Country,
) => {
  const phoneNumber = parsePhoneNumber(
    `${dial}${number}`,
    country as CountryCode,
  );
  return (
    phoneNumber?.isValid() || formatMessage("common.error.invalidPhoneNumber")
  );
};

export const validateTextStartsWithMinNumberOfLetters = (
  text: string,
  number: number,
) =>
  startsWithLettersNumber(number).test(text) ||
  formatMessage("common.error.startsWithMinNumberOfLetters", {
    values: {
      value: number,
    },
  });

export const validateTextExactNumberOfLetters = (
  text: string,
  number: number,
) =>
  exactLettersNumber(number).test(text) ||
  formatMessage("common.error.textExactNumberOfLetters", {
    values: { value: number },
  });

export const validateAsinsFormat = (value?: string) => {
  if (!value) return true;

  const asins = processAsinCSV(value);
  let validAsinsNum = 0;
  const isValid = asins.every((a) => {
    if (a.length === 0) return true;
    if (a.length !== 10 || !alphanumeric.test(a)) return false;

    validAsinsNum += 1;
    return true;
  });

  return (
    (isValid && validAsinsNum > 0) ||
    formatMessage("createStrategy.errors.invalidAsin")
  );
};

export const validateAsinsLeft = (value?: string) => {
  if (!value) return true;
  return formatMessage("createStrategy.errors.asinsLeft");
};

export const validateDropdownMaxItems = (
  restrictedLength: number,
  items: string[],
) =>
  items.length <= restrictedLength ||
  formatMessage("common.error.dropdown.maximumValueIs", {
    values: { value: restrictedLength },
  });

export const validateDropdownMinItems = (
  restrictedLength: number,
  items: string[],
) =>
  items.length >= restrictedLength ||
  formatMessage("common.error.dropdown.minimumValueIs", {
    values: { value: restrictedLength },
  });

export const validateDropdownExactItems = (
  restrictedLength: number,
  items: string[],
) =>
  items.length === restrictedLength ||
  formatMessage("common.error.dropdown.exactValueIs", {
    values: { value: restrictedLength },
  });
